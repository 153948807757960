<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img :src="logoUrl" :alt="appName" width="200"/>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Kullanıcı Girişi"/>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Kullanıcı Girişi
          </b-card-title>

          <b-card-text class="mb-2">
            Lütfen hesabınıza giriş yapın ve maceraya başlayın
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{invalid}">
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login">
              <!-- email -->
              <b-form-group
                  label="Kullanıcı Adı"
                  label-for="login-email">
                <validation-provider
                    #default="{ errors }"
                    name="Kullanıcı Adı"
                    vid="email"
                    rules="required">
                  <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="Kullanıcı Adı, Email Adresi veya Telefon Numarası"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Şifre</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Şifremi unuttum?</small>
                  </b-link>
                </div>
                <validation-provider
                    #default="{ errors }"
                    name="Şifre"
                    vid="password"
                    rules="required">
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Şifre"/>
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"/>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                  Beni Hatırla
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="invalid">
                Giriş Yap
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Hesabınız yok mu? </span>
            <b-link>
              <span>&nbsp;Hesap oluştur</span>
            </b-link>
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from "@/auth/jwt/useJwt";
import {getHomeRouteForLoggedInUser} from "@/auth/utils";
import {$themeConfig} from "@themeConfig"
import axios from "@/libs/axios";

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: $themeConfig.app.appIsDebug ? 'Uoz852369' : '',
      userEmail: $themeConfig.app.appIsDebug ? 'uzeyir.oztemur@sozal.com.tr' : '',
      sideImg: require('@/assets/images/pages/login/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/login/login-v2.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/logo/logo_light.png')
        return this.sideImg
      }
      return $themeConfig.app.appLogoImage
    },
    appName() {
      return $themeConfig.app.appName
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            userName: this.userEmail,
            password: this.password,
          }).then(response => {
            if (response.data.success === true) {
              const ability = [
                {
                  action: 'manage',
                  subject: 'all',
                },
              ];

              let userData = response.data.data
              userData.ability = ability;

              useJwt.setToken(response.data.data.token.token)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(ability)

              axios.get('/useremployees/current').then(response => {
                localStorage.setItem('employeeData', JSON.stringify(response.data.data))
              }).catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata',
                    text: error.response !== undefined ? error.response.data.message : 'Giriş sırasında hata oluştu!',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }).finally(message => {
                this.$router.replace(getHomeRouteForLoggedInUser('admin')).then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Hoşgeldiniz ${response.data.data.name}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Başarıyla giriş yaptınız. Şimdi keşfetmeye başlayabilirsiniz!`,
                    },
                  })
                })
              })

            } else {
              this.$refs.loginForm.setErrors(response.data.message)
            }
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata',
                text: error.response !== undefined ? error.response.data.message : 'Giriş sırasında hata oluştu!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>
